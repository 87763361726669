@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  /* font-optical-sizing: auto; */

}

body{
  background: #000;
}

h1,h4,p,
a{
  color: white;
  text-decoration: none;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background-color: transparent; /* Default button color */
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3; /* Darker color on hover */
  border-color: #0056b3; /* Darker color on hover */
}

.btn:active {
  background-color: #004080; /* Even darker color when clicked */
  border-color: #004080; /* Even darker color when clicked */
}
