.footer {
    background-color: rgba(19, 19, 19, 0.8);
    color: #fff;
    padding: 6rem 0;
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .footer-container {
    display: grid;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1140px;
    margin: auto;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
  }
  
  .footer-container .left {
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    display: flex;
    padding: 1rem;
  }
  
  .footer-container .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  .location, .phone, .email {
    margin-bottom: 20px;
  }
  
  .location {
    display: flex;
    margin-bottom: 0.8rem;
  }

  
  .location p, .phone h4, .email h4 {
    margin: 0;
    
  }
  
  .social {
    display: flex;
    align-items: center;
  }
  
  .social > * {
    margin-right: 20px;
  }
  
  .social > *:last-child {
    margin-right: 0;
  }
  
 @media screen and (max-width: 640px){
    .footer-container {
        grid-template-columns: 1fr;
      }
    
 }