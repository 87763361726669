.contact-form {
    margin: 20px auto;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* background-color: #f9f9f9; */
    color: #ccc;
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    height: 150px; /* Set height for the textarea */
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Button color */
    color: #fff; /* Text color */
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3; /* Button color on hover */
  }
  