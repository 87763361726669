.menu {
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
}

.menu h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #ccc;
}

.menu-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}

.menu-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.menu-item p {
  margin: 10px 0;
}

.menu-item p.description {
  font-size: 14px;
}

.menu-item p.price {
  font-weight: bold;
  color: #007bff;
}

.menu-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

/* @media (min-width: 1040px) {
  .menu-items {
    grid-template-columns: repeat(4, minmax(240px, 1fr)); /* Adjusted minimum width for larger items */
  /* } */
/* } */
